<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">用户列表</h4>
            <div class="row mt-4">
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="tableData" :fields="fields" responsive="sm" per-page=0 :sort-by.sync="sortBy"
                :no-provider-paging=false :sort-desc.sync="sortDesc" :filter="filter">
                <template v-slot:cell(user_img)="data">
                  <span v-html="data.value"></span>
                </template>

                <template v-slot:cell(user_of_true)="data">
                  <el-tag v-if="data.value">是</el-tag>
                  <el-tag v-else type="info">否</el-tag>
                </template>

                <template v-slot:cell(tool)="data">
                  <router-link :to="'/user/usercomm/'+data.item.user_uuid">
                    <b-button style="margin-right: 10px;" variant="warning">查看帖子
                    </b-button>
                  </router-link>
                  <router-link :to="'/user/userleave/'+data.item.user_uuid">
                    <b-button style="margin-right: 10px;" variant="info">查看留言
                    </b-button>
                  </router-link>

                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <div class="paginationClass">
                      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.currentPage" :page-sizes="[10,20, 30, 40, 50]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                      </el-pagination>
                    </div>
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  // import { tableData } from "./dataAdvancedtable";
  /**
   * Advanced table component
   */
  export default {
    page: {
      title: "用户列表",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        tableData: '',
        title: "用户列表",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "用户列表",
            active: true
          }
        ],
        filter: null,
        // 排序
        sortBy: "go_time",
        sortDesc: true,
        fields: [
          { key: "bx1", label: "用户名" },
          { key: "mobile", label: "手机号" },
          { key: "user_uuid", label: "UUID" },
          {
            key: "user_img", label: "头像", valign: 'middle', formatter(value, key, item) {
              var result = "";
              result = `<a class = "view" target="_blank"  href="${utils.Img_Url + value}"><img style="width:70px;height:70px;"   src="${utils.Img_Url + value}" /></a>`;
              return result;
            }, events: 'operateEvents'
          },
          { key: "go_time", label: "注册时间", sortable: true },
          { key: "update", label: "活跃时间", sortable: true },
          {
            key: "user_of_true", label: "真实用户", sortable: true,
          },
          {
            key: "tool", label: "工具栏"
          },
        ],
        EditRowData: "",
        LogoFile: "",
        page: {
          pageSize: 10,//每页显示条数
          currentPage: 1,//当前页码数
          total: 0,
        },
        Editindex: ""
      };
    },
    computed: {
    },
    mounted() {
    },
    created() {
      this.CheckParentCompany()

      window.addEventListener("keydown", this.handkeyCode, true); //开启监听键盘按下事件

    },
    methods: {
      // 键盘按下事件
      handkeyCode(e) {
        let key = null;
        if (window.event === undefined) {
          key = e.keyCode;
        } else {
          key = window.event.keyCode;
        }
        if (this.modalShow == true && key === 13) {

        }
      },
      // 删除
      delRows(item,) {
        _API_.ParentCompany({
          name: item,
        }).then((res) => {
          this.CheckParentCompany()
        })
      },
      // 查看帖子
      CheckComm(Item, Editindex) {
        console.log(UserMethods)
        UserMethods.set_comm_uuid(Item.user_uuid)
        console.log(_store_.state.user)
        this.$router.push({
          name: "UserComm",
          params: { uuid: Item.user_uuid },
        });
      },
      // 查看留言
      CheckLeave(Item, Editindex) {
        this.$router.push({
          name: "UserLeave",
          params: { uuid: Item.user_uuid },
        });
      },
      // 获取数据
      CheckParentCompany() {
        _API_.UserList({
          page: this.page.currentPage,
          size: this.page.pageSize
        }).then((res) => {
          this.tableData = res.data
          this.page.total = res.count
        })
      },
      // 更改Size
      handleSizeChange(size) {
        this.page.currentPage = 1
        this.page.pageSize = size
        this.CheckParentCompany()
      },
      // 更改Page
      handleCurrentChange(page) {
        this.page.currentPage = page
        this.CheckParentCompany()
      }

    }
  };
</script>
<style>
  .el-form-item {
    margin-bottom: 10px
  }
</style>